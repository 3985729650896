import { Link, useLocation } from "react-router-dom";
import useLangNavigate from "../common/useLangNavigate";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";

export function ThankYouRegister() {
  const navigate = useLangNavigate();
  const location = useLocation();
  const { t } = useTranslation();

  const email = location?.state?.email;

  function handleContinue() {
    return navigate("/confirm-registration", "", { state: { email } });
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section className="py-100 container">
      <div className="text-center mt-5">
        <div className="row justify-content-center">
          <div className="col-md-6">
            <div className="card shadow-lg bg-transparent p-4">
              <h1 className="display-4">{t("thank_you_register.congrats")}</h1>

              <p className="lead mt-3">
                {t("thank_you_register.have_any_questions")}
              </p>

              <p className="text-muted">
                <a href="https://talegacyremembered.com/contact">
                  {t("thank_you_register.contact_us")}{" "}
                </a>
                .
              </p>

              <p className="text-muted">
                <Link to={"/"}>{t("thank_you_register.write_a_book")} </Link>.
              </p>

              <div className="mt-4" onClick={handleContinue}>
                <a href="/" className="aai-btn btn-pill-solid">
                  {t("continue")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
