import "./App.css";
import React, { useContext, useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Login from "./components/login/Login";
import Header from "./core/routes/header/Header";
import Footer from "./core/routes/footer/Footer";
import RequestErrorMessage from "./components/_components/request-error-message/RequestErrorMessage";
import {
  deleteCookie,
  getCookie,
  setCookie,
  showToast,
} from "./core/common-service/CommonService";
import { postRefreshToken, setDefaultHeader } from "./core/api/Api";
import { StoreContext } from "./core/context/StoreContext";
import Register from "./components/register/Register";
import ConfirmRegister from "./components/confirm-register/ConfirmRegister";
import Home from "./components/home/Home";
import Questions from "./components/home/general-categories/general-categories-options/questions/Questions";
import ProtectedRoute from "./core/routes/protected_route/ProtectedRoute";
import GeneralCategories from "./components/home/general-categories/GeneralCategories";
import QuestionCategories from "./components/home/general-categories/general-categories-options/GeneralCategoriesOptions";
import NotFound from "./core/routes/not-found/NotFound";
import ForgotPassword from "./components/forgot-password/ForgotPassword";
import ResetPassword from "./components/reset-password/ResetPassword";
import MyBooks from "./components/my-books/MyBooks";
import Subscriptions from "./components/subscriptions/Subscriptions";
import { useTranslation } from "react-i18next";
import Payment from "./components/payment/Payment";
import Profile from "./components/profile/Profile";
import GiftCard from "./components/gift-card/GiftCard";
import GiftCardPayment from "./components/gift-card/gift-card-payment/GiftCardPayment";
import ManageSubscriptions from "./components/subscriptions/ManageSubscriptions";
import GiftCardForm from "./components/gift-card/gift-card-form/GiftCardForm";
import ThankYouPurchase from "./components/thankyou/ThankYouPurchase";
import { ThankYouRegister } from "./components/thankyou/ThankYouRegister";
import PrintingSection from "./components/printing/PrintingSection";
import PrintingPaymentPage from "./components/printing/PrintingPamentPage";
import ScrollToTop from "./components/common/ScrollToTop";
import useLangNavigate from "./components/common/useLangNavigate";
import { Navigate } from "react-router-dom";

const supportedLanguages = ["en", "fr", "es", "it"];

const PreLoader = () => {
  const { setIsLoggedIn, setUser } = useContext(StoreContext);
  const { t, i18n } = useTranslation();
  const navigate = useLangNavigate();
  // const { lang } = useParams();

  // useEffect(() => {
  //   // Use lang from URL, defaulting to "en" if unsupported
  //   if (!supportedLanguages.includes(lang)) {
  //     navigate(`/en${window.location.pathname.replace(/^\/[^/]+/, "")}`, {
  //       replace: true,
  //     });
  //     i18n.changeLanguage("en");
  //   } else {
  //     i18n.changeLanguage(lang);
  //   }
  // }, [lang, navigate, i18n]);

  const checkToken = () => {
    const token = sessionStorage.getItem("token") || getCookie("token");

    if (token) {
      setDefaultHeader("Bearer " + token);
      postRefreshToken()
        .then((res) => {
          const response = res.data;
          if (response?.success) {
            const token = response?.success?.token;
            const user = response?.success?.user;
            if (user?.is_verified) {
              setIsLoggedIn(true);
              setUser(user);
              setCookie("token", token);
            } else {
              showToast({
                type: "error",
                message: t("error.user_not_allowed"),
              });
            }
          } else {
            setIsLoggedIn(false);
            sessionStorage.clear();
            deleteCookie("token");
            navigate("/login", "en");
          }
        })
        .catch((e) => {
          setIsLoggedIn(false);
          sessionStorage.clear();
          deleteCookie("token");
          navigate("/login", "en");
        });
    } else {
      setIsLoggedIn(false);
    }
  };

  useEffect(() => {
    checkToken();
    return () => {};
  }, []);
};

export default function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/:lang/*" element={<PreLoader />} />
      </Routes>
      <Header />
      <RequestErrorMessage />
      <main className="wrapper">
        <Routes>
          {/* Default route */}
          <Route
            path="/"
            element={<Navigate to="/fr/general-categories/1" replace />}
          />
          {/* Other routes */}
          <Route
            path="/:lang"
            element={
              <ProtectedRoute>
                <Home />
              </ProtectedRoute>
            }
          />
          <Route path="/:lang/login" element={<Login />} />
          <Route path="/:lang/register" element={<Register />} />
          <Route
            path="/:lang/confirm-registration"
            element={<ConfirmRegister />}
          />
          <Route path="/:lang/forgot-password" element={<ForgotPassword />} />
          <Route path="/:lang/reset-password" element={<ResetPassword />} />
          <Route
            path="/:lang/my-books"
            element={
              <ProtectedRoute>
                <MyBooks />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:lang/manage-subscriptions"
            element={
              <ProtectedRoute>
                <ManageSubscriptions />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:lang/subscriptions"
            element={
              <ProtectedRoute>
                <Subscriptions />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:lang/profile"
            element={
              <ProtectedRoute>
                <Profile />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:lang/general-categories/:service_id"
            element={<GeneralCategories />}
          />
          <Route
            path="/:lang/general-categories-options/:service_id/:general_category_id"
            element={<QuestionCategories />}
          />
          <Route
            path="/:lang/questions/:service_session_id"
            element={<Questions />}
          />
          <Route path="/:lang/payment" element={<Payment />} />
          <Route path="/:lang/gift-card" element={<GiftCard />} />
          <Route
            path="/:lang/gift-card-payment"
            element={<GiftCardPayment />}
          />
          <Route path="/:lang/gift-card-form" element={<GiftCardForm />} />
          <Route
            path="/:lang/thankyou/purchase"
            element={<ThankYouPurchase />}
          />
          <Route
            path="/:lang/thankyou/register"
            element={<ThankYouRegister />}
          />
          <Route
            path="/:lang/printing"
            element={
              <ProtectedRoute>
                <PrintingSection />
              </ProtectedRoute>
            }
          />
          <Route
            path="/:lang/printing-payment"
            element={
              <ProtectedRoute>
                <PrintingPaymentPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="*"
            element={<Navigate to="/fr/general-categories/1" replace />}
          />
        </Routes>
      </main>
      <Footer />
    </BrowserRouter>
  );
}
