import React, { useState, useEffect } from "react";
import {
  postCancelSubscription,
  getUserSubscription,
  getSubscriptionDetails,
} from "../../core/api/Api";
import { showToast } from "../../core/common-service/CommonService"; // To show notifications
import { useTranslation } from "react-i18next";
import "./ManageSubscriptions.css";
import useLangNavigate from "./../common/useLangNavigate";
import Modal from "../common/modal/Modal";
import { showHideModal } from "../../core/common-service/CommonService";

const modal_unsubscribe_id = "modal_unsubscribe";

const ManageSubscriptions = () => {
  const navigate = useLangNavigate();
  const { t } = useTranslation();
  const [subscription, setSubscription] = useState(null);
  const [status, setStatus] = useState(null);
  const [isChecked, setIsChecked] = useState(false); // Checkbox state

  // Utility function to determine subscription status
  const determineSubscriptionStatus = (data) => {
    if (data.cancel_date) {
      return "unsubscribed";
    } else if (data.cancel_request) {
      return "pending_cancellation";
    } else {
      return "subscribed";
    }
  };

  // Fetch subscription details on component mount
  useEffect(() => {
    const fetchSubscription = async () => {
      const response = await getUserSubscription();
      if (response.data && response.data.id) {
        setSubscription(response.data);
        const resp = await getSubscriptionDetails(subscription);
        console.log("res ", resp);
        setStatus(determineSubscriptionStatus(response.data));
      } else {
        setStatus("unsubscribed");
      }
    };

    fetchSubscription();
  }, []);

  // Handle subscription (navigate to the subscription page)
  const subscribe = () => {
    navigate("/subscriptions");
  };

  const showUnsubscribeModal = () => {
    showHideModal(modal_unsubscribe_id);
  };

  const confirmUnsubscribe = async () => {
    showHideModal(modal_unsubscribe_id);

    try {
      const response = await postCancelSubscription();
      if (response.data.status === "success") {
        showToast({ type: "success", message: t("subscribe.unsubscribed") });
        setSubscription(response.data);
        setStatus(determineSubscriptionStatus(response.data));
      } else {
        showToast({ type: "error", message: t("subscribe.error") });
      }
    } catch (error) {
      showToast({ type: "error", message: t("subscribe.error") });
    }
  };

  return (
    <section className="manage-subscriptions py-100">
      <div className="container">
        <div className="subscription-page">
          {status === "subscribed" && (
            <div className="subscription-box subscribed">
              <h2>{t("subscribe.active")}</h2>
              <p>
                <strong>{t("subscribe.note")}</strong>{" "}
                {t("subscribe.stop_warning")}
              </p>
              <ul className="benefits-list">
                {t("subscribe.benefits", { returnObjects: true }).map(
                  (benefit, index) => (
                    <li key={index}>
                      <i className={`bi bi-${benefit.icon} benefit-icon`}></i>{" "}
                      {benefit.text}
                    </li>
                  )
                )}
              </ul>
              <p>
                <i className="bi bi-person-badge"></i> {t("subscribe.plan")}:{" "}
                <strong>
                  {subscription?.plan || t("subscribe.default_plan")}
                </strong>
              </p>
              <button
                className="unsubscribe-btn"
                onClick={showUnsubscribeModal}
              >
                {t("subscribe.unsubscribe")}
              </button>
            </div>
          )}

          {status === "pending_cancellation" && (
            <div className="subscription-box pending-cancellation">
              <h2>{t("subscribe.pending")}</h2>
              <p>{t("subscribe.pending_message")}</p>
              <ul className="benefits-list">
                <li>
                  <i className="bi bi-hourglass-split"></i>{" "}
                  {t("subscribe.revoked")}
                </li>
                <li>
                  <i className="bi bi-journal-check"></i>{" "}
                  {t("subscribe.keep_free_access")}
                </li>
              </ul>
              <p>
                <i className="bi bi-person-badge"></i> {t("subscribe.plan")}:{" "}
                <strong>
                  {subscription?.plan || t("subscribe.default_plan")}
                </strong>
              </p>
            </div>
          )}

          {status === "unsubscribed" && (
            <div className="subscription-box not-subscribed">
              <h2>{t("subscribe.offer")}</h2>
              <p>{t("subscribe.price")}</p>
              <p>{t("subscribe.benefits_summary")}</p>
              <ul className="benefits-list">
                {t("subscribe.benefits", { returnObjects: true }).map(
                  (benefit, index) => (
                    <li key={index}>
                      <i className={`bi bi-${benefit.icon} benefit-icon`}></i>{" "}
                      {benefit.text}
                    </li>
                  )
                )}
              </ul>

              {/* Checkbox for accepting terms */}
              <div className="terms-container">
                <input
                  type="checkbox"
                  id="acceptTerms"
                  checked={isChecked}
                  onChange={(e) => setIsChecked(e.target.checked)}
                />
                <label htmlFor="acceptTerms">
                  {t("subscribe.accept_terms")}{" "}
                  <a
                    href="https://talegacyremembered.com/conditions-generales-de-vente/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("subscribe.terms_link")}
                  </a>
                </label>
              </div>

              {/* Subscribe button is disabled if checkbox is not checked */}
              <button
                className="subscribe-btn"
                onClick={subscribe}
                disabled={!isChecked}
              >
                {t("subscribe.subscribe")}
              </button>
            </div>
          )}
        </div>
      </div>

      <Modal id={modal_unsubscribe_id}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8">
              <div className="aai-section-title mb-3 text-center mb-5">
                <p className="section-desc">{t("subscribe.stop_warning")}</p>
                <div className="mt-4">
                  <button
                    className="aai-gradient-outline-btn me-4"
                    onClick={confirmUnsubscribe}
                  >
                    {t("yes")}
                  </button>
                  <button
                    className="aai-btn btn-pill-solid"
                    onClick={() => showHideModal(modal_unsubscribe_id)}
                  >
                    {t("no")}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </section>
  );
};

export default ManageSubscriptions;
