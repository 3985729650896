import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./PrintingSection.css";
import { useTranslation } from "react-i18next";
import useLangNavigate from "./../common/useLangNavigate";
import bookImage from "../../assets/img/others/print_book.jpeg";

const PrintingSection = () => {
  const [file, setFile] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  const [price, setPrice] = useState(0);
  const [copies, setCopies] = useState(1); // New state for number of copies
  const [address, setAddress] = useState(null);
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useLangNavigate();
  const { t } = useTranslation();

  const calculatePrice = (file, copies) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("copies", copies); // Add number of copies to form data

    setLoading(true);

    axios
      .post("uploadDocx", formData)
      .then((response) => {
        setPageCount(response.data.pageCount);
        setPrice(response.data.price);
      })
      .catch((error) => {
        console.error("Error calculating price:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (
      selectedFile &&
      selectedFile.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
    ) {
      setFile(selectedFile);
      calculatePrice(selectedFile, copies); // Call price calculation with selected file and copies
    }
  };

  const handleCopiesChange = (e) => {
    const newCopies = e.target.value;
    setCopies(newCopies);
    if (file) {
      calculatePrice(file, newCopies); // Recalculate price when copies input is changed
    }
  };

  const handleSubmit = () => {
    navigate("/printing-payment", "", {
      state: { file, price, copies, address, postalCode, city },
    });
  };

  return (
    <section className="printing-section py-100">
      <div className="container">
        <div className="content-wrapper">
          {/* Right Content */}
          <div className="right-content">
            <h1>{t("book_format_printing")}</h1>
            <img src={bookImage} alt="Book Printing Example" />
          </div>

          {/* Left Content */}
          <div className="left-content">
            <h1>{t("upload_your_book")}</h1>
            <div className="custom-file-upload">
              <label className="custom-file-label" htmlFor="file-upload">
                {file ? file.name : t("choose_file")}
              </label>
              <input
                id="file-upload"
                type="file"
                accept=".docx"
                onChange={handleFileChange}
                className="file-input-hidden"
              />
            </div>

            {file && (
              <>
                <div className="copies-input-container">
                  <label htmlFor="copies">{t("number_of_copies")}</label>
                  <input
                    type="number"
                    id="copies"
                    min="1"
                    value={copies}
                    onChange={handleCopiesChange}
                  />
                </div>

                <div className="result">
                  <p>
                    {t("page_count")}:{" "}
                    <span className="value">{pageCount}</span>
                  </p>
                  <p>
                    {t("price")}: <span className="value">{price} EUR</span>
                  </p>
                </div>

                <div className="form-fields">
                  {/* Address, Postal Code, and City Inputs */}
                  <div className="input-container">
                    <label htmlFor="address" className="input-label">
                      {t("address")}
                    </label>
                    <input
                      type="text"
                      id="address"
                      value={address}
                      onChange={(e) => setAddress(e.target.value)}
                      className="input-field"
                    />
                  </div>

                  <div className="input-container">
                    <label htmlFor="postal-code" className="input-label">
                      {t("postal_code")}
                    </label>
                    <input
                      id="postal-code"
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                      className="input-field"
                    />
                  </div>

                  <div className="input-container">
                    <label htmlFor="city" className="input-label">
                      {t("city")}
                    </label>
                    <input
                      type="text"
                      id="city"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      className="input-field"
                    />
                  </div>

                  {file && address && (
                    <div className="submit-button-container">
                      <button
                        onClick={handleSubmit}
                        disabled={loading}
                        className={`submit-button ${loading ? "loading" : ""}`}
                      >
                        {loading ? t("processing") : t("submit")}
                      </button>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default PrintingSection;
