import React, { useRef, useState, useEffect } from "react";
import bg from "../../assets/img/bg/aai-feature-bg.jpeg";
import KRGlue from "@lyracom/embedded-form-glue";
import {
  getSubscriptionPaymentStatusCallback,
  PAYMENT_GATEWAY_PUBLIC_KEY,
  postCreateSubscriptionPaymentForm,
} from "../../core/api/Api";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { showToast } from "../../core/common-service/CommonService";
import useLangNavigate from "../../components/common/useLangNavigate";

const Subscriptions = () => {
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState("");
  const [formToken, setFormToken] = useState("");
  const mounted = useRef(true);
  const { t } = useTranslation();
  const [message, setMessage] = useState("");
  const [paymentStatus, setPaymentStatus] = useState("");
  const navigate = useLangNavigate();
  const location = useLocation();
  const { i18n } = useTranslation();

  useEffect(() => {
    if (paymentStatus === "PAID") {
      showToast({
        type: "success",
        message: t("subscribe.subscribed_successfully"),
      });

      const timer = setTimeout(() => {
        navigate("/my-books", "");
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [paymentStatus, navigate]);

  useEffect(() => {
    window.scroll(0, 0);
    async function setupPaymentForm() {
      const endpoint = "https://static.scelliuspaiement.labanquepostale.fr";
      const publicKey = PAYMENT_GATEWAY_PUBLIC_KEY;

      try {
        postCreateSubscriptionPaymentForm({}).then(async (res) => {
          const response = res?.data?.success;
          if (response) {
            const formToken = response?.formToken;
            const orderId = response?.order_id;

            const { KR } = await KRGlue.loadLibrary(
              endpoint,
              publicKey
            ); /* Load the remote library */

            await KR.setFormConfig({
              /* set the minimal configuration */
              formToken,
              "kr-language":
                i18n.language /* to update initialization parameter */,
            });

            await KR.renderElements(
              "#myPaymentForm"
            ); /* Render the payment form into myPaymentForm div */

            await KR.onSubmit(async (res) => {
              const orderStatus = res.clientAnswer.orderStatus;
              if (orderStatus === "PAID") {
                setPaymentStatus(orderStatus);
                getSubscriptionPaymentStatusCallback(orderId)
                  .then((res) => {
                    const response = res?.data?.success;
                    if (mounted.current && response) {
                      showToast({ type: "success", message: response });
                    }
                  })
                  .catch((e) => {});
              }
              return false; // Return false to prevent the redirection
            });
          }
        });
      } catch (error) {
        navigate(-1);
        setMessage(error);
      }
    }
    setupPaymentForm();
    return () => {
      mounted.current = false;
    };
  }, []);

  return (
    <section
      className="aai-features py-100 payment"
      // style={{ background: `url(${bg}) no-repeat center center/cover` }}
    >
      {paymentStatus === "PAID" && (
        <div className="container pb-100">
          <div className="row">
            <div className="col-12">
              <h2 className="paid-message-header">
                Nous avons confirmé votre abonnement.
              </h2>
              <p className="paid-message-description">
                Profitez d'écritures de livres illimitées, sans aucune limite de
                créativité ou de narration.
              </p>
            </div>
          </div>
        </div>
      )}
      <div className="form">
        <div className="d-flex align-items-center justify-content-center">
          <div id="myPaymentForm">
            <div className="kr-smart-form" kr-card-form-expanded="true"></div>
          </div>
          <div data-test="payment-message">{message}</div>
        </div>
      </div>
    </section>
  );
};

export default Subscriptions;
