import React, { useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";

const Captcha = ({ onVerify }) => {
  const { t, i18n } = useTranslation();
  const [captchaToken, setCaptchaToken] = useState(null);

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
    if (onVerify) {
      onVerify(token); // Pass the token back to the parent component
    }
  };

  const handleCaptchaExpired = () => {
    setCaptchaToken(null);
    if (onVerify) {
      onVerify(null); // Notify parent component that the token has expired
    }
  };

  // NOTE: refactor this into .env file
  const RECAPTCHA_SITE_KEY = "6LckGIQqAAAAAFc3O-VjJkiQxCXffAZBEy6W5i_r";

  return (
    <ReCAPTCHA
      sitekey={RECAPTCHA_SITE_KEY}
      onChange={handleCaptchaChange}
      onExpired={handleCaptchaExpired}
      hl={i18n.language}
    />
  );
};

export default Captcha;
